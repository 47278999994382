<script>
import { required } from "vuelidate/lib/validators";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import tableData from "@/components/tablecomponent";
import Multiselect from "vue-multiselect";
// import DatePicker from "vue2-datepicker";
import ModalServiceComponent from "@/components/modalServiceComponent.vue";
import btnComponent from "@/components/btnComponent";
// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "การเข้ารับบริการ",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    tableData,
    ModalServiceComponent,
    Multiselect,
    btnComponent,
  },
  // Multiselect, DatePicker,
  data() {
    return {
      // tableData: tableData,
      isLoading: false,
      errormessage: "กรุณาเลือกสาขา",

      title: "การเข้ารับบริการ",
      items: [
        {
          text: "บริการ",
          active: true,
        },
        {
          text: "การเข้ารับบริการ",
          active: false,
        },
      ],
      userBranchId: "",
      userBranch: "",
      apptId: "",
      rowsSelect: [],
      service: [],
      rowsAppointment: [],
      rowsAppointmentDetail: [],
      svId: "",
      hasGsJob: 0,
      hasBpJob: 0,
      hasWcJob: 0,
      apptDate: "",
      nameCus: "",
      vin: "",
      svDate: "",
      type: "",
      disTance: "",
      insurance: "",
      warranty: "",
      requestType: "",
      licensePlate: "",
      // vin: "",
      typeOptions: [
        {
          id: 1,
          typeId: 1,
          name: "GS",
        },
        {
          id: 2,
          typeId: 1,
          name: "BP",
        },
        {
          id: 3,
          typeId: 1,
          name: "Warranty",
        },
      ],
      requestTypeOptions: [
        {
          id: 1,
          name: "PRE",
        },
      ],
      distanceOptions: [
        {
          id: 1,
          name: "เช็คระยะ 10,000 กิโล",
        },
        {
          id: 2,
          name: "เช็คระยะ 20,000 กิโล",
        },
        {
          id: 3,
          name: "เช็คระยะ 30,000 กิโล",
        },
      ],
      chk: "",
      selected: [],
      selectedAppointment: "",
      submitform: false,
      rowsTotalpages: "",
      rowsperPage: "",
      rowsPage: "",
      totalRows: 1,
      currentPage: 1,
      currentPageAppointment: 1,
      perPage: 10,
      perPageAppointment: 10,
      arrTest: [],
      totalRecords: 0,
      pageOptions: [10, 25, 50, 100],
      filter: {
        vin: "",
        licensePlate: "",
        mobilePhone: "",
        branch: "",
      },
      filterOn: [],
      rows: [],
      rowsService: [],
      rowsUser: [],
      rowsCusfull: "",
      rowsSearch: "",
      sortBy: "age",
      sortDesc: false,
      fieldsApp: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "apptId",
          label: "appId",
        },
        {
          key: "apptCode",
          label: "รหัสนัดหมาย",
        },
        {
          key: "customerName",
          label: "ชื่อลูกค้านัดหมาย",
        },
        {
          key: "customerMobilePhone",
          label: "เบอร์โทรศัพท์",
        },
        {
          key: "apptTime",
          label: "เวลานัดหมาย",
        },
        {
          key: "vehiclePlate",
          label: "ป้ายทะเบียน",
        },
        {
          key: "vehicleModelEn",
          label: "รุ่น",
        },
      ],
      fields: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "svCode",
          sortable: true,
          label: "เลขที่เข้ารับบริการ",
        },
        {
          key: "vin",
          sortable: true,
          label: "เลขตัวถัง",
        },
        {
          key: "cusName",
          sortable: true,
          label: "ชื่อลูกค้า",
        },
        {
          key: "licensePlate",
          sortable: true,
          label: "ป้ายทะเบียน",
        },
        // {
        //   key: "vehicleBrandEn",
        //   sortable: true,
        //   label: "ยี่ห้อ",
        // },
        {
          key: "vehicleModelEn",
          sortable: true,
          label: "รุ่น",
        },
        {
          key: "distanceNameTh",
          sortable: true,
          label: "ประเภท",
        },
        // {
        //   key: "mobilePhone",
        //   sortable: true,
        //   label: "เบอร์โทรศัพท์",
        // },
        {
          key: "svDate",
          sortable: true,
          label: "วันที่เข้ารับบริการ",
        },
        // {
        //   key: "branchName",
        //   sortable: true,
        //   label: "สาขา",
        // },
        {
          key: "action",
          label: appConfig.action,
        },
      ],
      overlayFlag: false,
      rowsMasterSD: [],
      distance: "",
      expectFinishDate: "",
      expectFinishTime: "",

      platePrefixNumber: "",
      plateNumber: "",
      plateProvinceId: "",
      mobilePhone: "",
      name: "",
      familyName: "",
      rowsProvince: [],
      rowsPlate: [],
      optionBranch: [],
    };
  },
  validations: {
    filter: {
      branch: {
        required,
      },
    },
  },
  computed: {
    /**
     * Total no. of records
     */
    // rows() {
    //   return this.rowsTest.length;
    // },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
      if(user.branchId == item.branchId) {
        this.filter.branch = {branchId: item.branchId, nameTh: item.nameTh}
      }
    });
    this.userBranch = arrayBranch;
    this.userBranchId = user.branchId
    this.optionBranch = user.userAccessBranch;
    this.totalRows = this.rows.total;
    this.totalRows = this.rowsAppointment.total;
    this.getData();
  },
  created() {
    
  },
  methods: {
    getData: function() {
      this.overlayFlag = true;
      this.isLoading = true;
      useNetw
        .get("api/service", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            "branchId[]": this.filter.branch != null ? this.filter.branch.branchId : this.userBranchId,
            vin: this.filter.vin,
            licensePlate: this.filter.licensePlate,
            mobilePhone: this.filter.mobilePhone,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.rows.to = response.data.to;
          this.rows.from = response.data.from;
          this.rows.total = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          this.isLoading = false;
        });
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    onRowSelected(rows) {
      this.selected = rows;
    },
    deleteVehicle(rows) {
      this.vehicleId = rows;
      this.overlayFlag = true;
      useNetw
        .delete("api/vehicle/delete", {
          data: {
            vehicleId: this.vehicleId,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    deleteService(svId) {
      this.overlayFlag = true;
      useNetw
        .delete("api/service/delete", {
          data: {
            svId: svId,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message + '<br>' + err.response.data.validationMessage),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    alertDelete(svId) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบใบเข้ารับบริการหรือไม่ !!?",
          // text: "ต้องการออกใบกำกับภาษีหรือไม่ !!",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.deleteService(svId);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
        });
    },
    handleSearch() {
      this.getData();
    },
    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.filter.$invalid != true) {
        this.handleSearch();
      }
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div class="card-body">
              <!-- <h4 class="card-title">Service List</h4> -->
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-6 col-sm-6 col-md-3">
                      <div class="mb-3 position-relative">
                        <label>สาขา:</label>
                        <multiselect
                          :options="optionBranch"
                          v-model="filter.branch"
                          :close-on-select="true"
                          :internal-search="true"
                          open-direction="bottom"
                          :show-labels="false"
                          label="nameTh"
                          placeholder=""
                          :class="{
                            'is-invalid': submitform && $v.filter.branch.$error,
                          }"
                        >
                          <!-- <span slot="noResult">ไม่พบข้อมูล</span> -->
                        </multiselect>
                        <div
                          v-if="submitform && $v.filter.branch.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.filter.branch.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-3">
                      <div class="mb-3 position-relative">
                        <label>ป้ายทะเบียน:</label>
                        <input
                          v-model="filter.licensePlate"
                          type="search"
                          class="form-control"
                          @keyup.enter="tooltipForm"
                        />
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-3">
                      <div class="mb-3 position-relative">
                        <label>เลขตัวถัง:</label>
                        <input
                          v-model="filter.vin"
                          type="search"
                          class="form-control"
                          @keyup.enter="tooltipForm"
                        />
                      </div>
                    </div>
                    <div class="col col-sm-6 col-md-3">
                      <div class="mb-3 position-relative">
                        <label>เบอร์โทรศัพท์:</label>
                        <input
                          v-model="filter.mobilePhone"
                          type="search"
                          class="form-control"
                          @keyup.enter="tooltipForm"
                        />
                      </div>
                    </div>

                    <!-- <div class="col-6 col-sm-5 col-md-2">
                            <div class="mb-3 position-relative"> 
                              <br>
                              <btnComponent @click="handleSearch" changeStyle="search" > </btnComponent>
                            </div>
                          </div> -->
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-7 col-md-12">
                <div class="mb-3 position-relative text-end">
                  <btnComponent @click="tooltipForm" changeStyle="search">
                  </btnComponent>
                  <b-button
                    v-b-modal.modalService
                    class="btn m-1"
                    variant="primary"
                  >
                    <i class="uil-plus"></i>
                    เปิด Service
                  </b-button>
                  <b-button
                    v-b-modal.modalServiceFast
                    class="btn m-1"
                    variant="primary"
                  >
                    <i class="uil-plus"></i>
                    เปิด Service แบบด่วน
                  </b-button>
                  <b-button
                    :to="{
                      name: 'servicegallery',
                    }"
                    class="btn m-1"
                    variant="primary"
                  >
                    <i class="uil-apps"></i>
                  </b-button>
                </div>
              </div>

              <!-- Search -->
              <div class="row justify-content-md-center">
                <div class="col-sm-12 col-md-12">
                  <div class="row align-items-center">
                    <div class="col-12 col-sm-3 col-md-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          แสดงผล&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                            @input="handlePageChange"
                          ></b-form-select
                          >&nbsp;รายการ
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <!-- Table -->

              <tableData :fields="fields" :items="rows">
                <template #cell(index)="rowsTest">
                  {{ rowsTest.index + 1 }}
                </template>
                <template #cell(action)="rows">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <router-link
                        href="javascript:void(0);"
                        class="px-2 text-primary"
                        v-b-tooltip.hover
                        :to="{
                          name: 'editCheckMycar',
                          params: { svId: rows.item.svIdBase64 },
                        }"
                        title="Edit"
                      >
                        <i class="uil uil-pen font-size-18"></i>
                      </router-link>
                    </li>
                    <li class="list-inline-item">
                      <router-link
                        href="javascript:void(0);"
                        class="px-2 text-success"
                        v-b-tooltip.hover
                        :to="{
                          name: 'servicedetail',
                          params: {
                            id: rows.item.svIdBase64,
                            url: 'servicelist',
                          },
                        }"
                        title="View"
                      >
                        <i class="uil uil-eye font-size-18"></i>
                      </router-link>
                    </li>
                    <li class="list-inline-item">
                      <a
                        href="javascript:void(0);"
                        class="px-2 text-danger"
                        v-b-tooltip.hover
                        title="Delete"
                        @click="alertDelete(rows.item.svId)"
                      >
                        <i class="uil uil-trash-alt font-size-18"></i>
                      </a>
                    </li>
                  </ul>
                </template>
              </tableData>

              <div class="row">
                <div class="col">
                  <div class="align-items-center">
                    แสดงผล {{ perPage }} หน้า {{ currentPage }} จาก
                    {{ this.rows.total }} รายการ
                  </div>
                </div>
                <div class="col">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows.total"
                        :per-page="perPage"
                        @change="handleChangePage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>

    <modal-service-component></modal-service-component>
  </Layout>
</template>
